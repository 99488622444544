<template>
  <CyTooltip
    v-if="icon"
    :disabled="!showTooltip"
    v-bind="{ [tooltipDirection]: true }">
    <template #activator="{ on }">
      <component
        v-if="isSvg"
        :class="['credential-icon credential-icon--svg', {
          'credential-icon--greyscale': greyscale,
          'credential-icon--no-margin': noMargin,
        }]"
        :height="size"
        :width="size"
        :alt="tooltipText"
        v-on="on"
        :is="icon"/>
      <img
        v-else-if="isImage"
        :class="['credential-icon credential-icon--image', {
          'credential-icon--greyscale': greyscale,
          'credential-icon--no-margin': noMargin,
        }]"
        :src="icon"
        :alt="tooltipText"
        :width="size"
        v-on="on">
      <v-icon
        v-else
        :class="['credential-icon credential-icon--icon', {
          'credential-icon--greyscale': greyscale,
          'credential-icon--no-margin': noMargin,
        }]"
        :style="`width: ${size}px;`"
        v-bind="{ size }"
        v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    {{ tooltipText }}
  </CyTooltip>
</template>

<script>
import images from '@/assets/images'
import REGEX from '@/utils/config/regex'

export const icons = {
  alibaba: images.alibaba.logo,
  aws: images.aws.logo,
  azure_storage: images.azurerm.logo,
  azure: images.azurerm.logo,
  azurerm: images.azurerm.logo,
  basic_auth: 'vpn_key',
  custom: 'fas fa-code',
  elasticsearch: images.elasticsearch.logo,
  flexibleengine: images.flexibleengine.logo,
  gcp: images.google.logo,
  gitlab: images.gitlab.logo,
  google: images.google.logo,
  http: 'language',
  kubernetes: images.kubernetes.logo,
  openstack: images.openstack.logo,
  ovh: images.ovh.logo,
  scaleway: images.scaleway.logo,
  scw: images.scaleway.logo,
  ssh: '$vuetify.icons.ssh',
  swift: images.swift.logo,
  terraform: images.terraform.logo,
  vault: images.vault.logo,
  vmware: images.vmware.logo,
  vsphere: images.vsphere.logo,
}

export default {
  name: 'CyIconCredential',
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: '30',
    },
    greyscale: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default () {
        return this.type
      },
    },
    tooltipDirection: {
      type: String,
      default: 'right',
    },
    devIconType: {
      type: String,
      default: 'original',
    },
  },
  computed: {
    icon () {
      const { type, devIconType } = this
      if (!type) return null
      let backUpDevIcon = null
      try {
        backUpDevIcon = require(`devicon/icons/${type}/${type}-${devIconType}.svg`)
      } catch (error) {}
      return icons[type] || backUpDevIcon
    },
    isSvg () {
      return _(this.icon).keys().includes('render')
    },
    isImage () {
      // The isString check is just for tests
      // (as they don't parse the image to receive a path)
      return !this.isSvg &&
        _.some([
          REGEX.IS_IMAGE.test(this.icon),
          !_.isString(this.icon),
        ])
    },
  },
}
</script>

<style lang="scss" scoped>
.credential-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  &--icon {
    color: get-color("primary");

    ::v-deep .icon {
      color: inherit;
    }
  }

  &--image {
    height: auto;
  }

  &--greyscale {
    filter: grayscale(1);
  }

  &--no-margin {
    margin: 0;
  }

  &:focus {
    outline: none;
  }
}
</style>
